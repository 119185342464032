<template>
  <div class="animated fadeIn">
    <b-card style="width:100%; height:65vh; border:0;">
      <h2 class="mb-4">401(k)</h2>
      <b-tabs content-class="mt-3" justified style="width:100%; border:0;">
        <b-tab title="General Information" style="height:100%" active>
          <b-row>
            <b-col cols="2">
              <inline-date-picker
                id="input:renewal_date"
                :value-single="data['Renewal Date']"
                label="Plan Renewal Date"
                :readonly="!$permitted('input:renewal_date').write"
                :mode="$constants.FORM_MODE.VIEW"
                @changed="updateRenewalDate"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="2">
              Documents:
            </b-col>
          </b-row>
          <hr />
          <iframe
            src="https://drive.google.com/embeddedfolderview?id=1Ljrq8DqH6PkW1mU3Qa9fJiVPEg1gbY1a"
            style="width:100%; height:100%; border:0;"
          ></iframe>
        </b-tab>
        <b-tab title="Eligibility">
          <ul class="w-50">
            <li>Full-time position</li>
            <li>
              You will become eligible to make Elective Deferral Contributions
              and receive Matching Contributions and Profit Sharing
              Contributions on the first day of each plan quarter, coincident
              with or next following the date you attain age 21 and you complete
              one (1) Year of Eligibility Service, provided that you are an
              Eligible Employee on that date.
            </li>
          </ul>
        </b-tab>
        <b-tab title="Internal Contact">
          <ul class="w-50">
            <li>Sebastian Saller (sebastian.saller@kovaldistillery.com)</li>
            <li>Oleg Raskin (oleg.raskin@kovaldistillery.com)</li>
          </ul>
        </b-tab>
        <b-tab title="Provider Information">
          <ul class="w-50">
            <li>Merrill Lynch Wealth Management</li>
            <li>Jason Burriss: jason.burriss@ml.com</li>
            <li>Fernando Arroyo: fernando.d.arroyo@ml.com)</li>
          </ul>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      data: {
        "Renewal Date": ""
      }
    };
  },
  created() {},
  mounted() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      this.data = await this.$api.get("benefit/401");
    },
    async updateRenewalDate(e) {
      this.data["Renewal Date"] = e.valueSingleAsString;

      await this.$api.put("benefit/401", this.data);
    }
  }
};
</script>

<style scoped>
::v-deep .tab-content {
  height: 90%;
}

::v-deep .tabs {
  height: 100%;
}
</style>
